export default {
  install(Vue, options) {
    Vue.isCCoEBaseline = function(name) {
      if (name == undefined) return false;

      const regex = /^(.*\.)?([^\.]+)$/i;
      const found = name.match(regex);

      if (found == null) return false;

      let shortName = found[found.length - 1];

      if (
        [
          "administratoraccess",
          "readonlyaccess",
          "securityaudit",
          "poweruseraccess",
          "cisotagedition",
          "billingaccess",
          "finopsaccess",
        ].includes(shortName.toLowerCase())
      ) {
        return true;
      } else return false;
    };

    Vue.prototype.$isCCoEBaseline = function(name) {
      return Vue.isCCoEBaseline(name);
    };

    Vue.prototype.$isAssignable = function(name) {
      if (Vue.isCCoEBaseline(name) && name.toLowerCase().includes("cisotagedition")) return false;
      return true;
    };
  },
};
