export default {
  setRegions(state, payload) {
    state.regions = payload;
  },
  setWorkInProgress(state, payload) {
    state.workInProgress = payload;
  },
  setMessages(state, payload) {
    state.messages = payload;
  },
  setLastLoadingTS(state) {
    state.lastLoadingTS = new Date().getTime();
  },
  setCurrentTS(state) {
    state.currentTS = new Date().getTime();
  },
};
