import axios from "axios";

export default {
  async loadEntities(context, payload) {
    try {
      const config = {
        headers: {
          authorizationToken: "Bearer " + payload.accessToken,
        },
      };
      const URL = process.env.VUE_APP_BACKEND_BASEURL + "/entities";
      const response = await axios.get(URL, config);
      if (response.status == 200) {
        let orgID = [];
        context.commit("setEntities", response.data);
        for (const k of response.data) {
          orgID.push({ BU: k.buID, OrgId: k.OrgId, BE: k.entityName });
          // orgID.push(k.OrgId);
        }
        context.commit("setOrgId", orgID);
      }
    } catch (e) {
      console.log(e);
      // silently die
    }
  },
};
