import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";
import ProtectedRole from "./protectedRole.js";
import Permission from "./permission.js";

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(ProtectedRole);
Vue.use(Permission, { store });
Vue.prototype.$log = console.log.bind(console);

Vue.prototype.$conf = {
  oidcconfig: {
    baseUrl: process.env.VUE_APP_BASEURL,
    clientId: process.env.VUE_APP_CLIENTID,
    redirectUri: process.env.VUE_APP_REDIRECTURI,
    authParams: {
      pkce: process.env.VUE_APP_AUTHPARAMS_PKCE === "true",
      issuer: process.env.VUE_APP_AUTHPARAMS_ISSUER,
      display: process.env.VUE_APP_AUTHPARAMS_DISPLAY,
      scopes: process.env.VUE_APP_AUTHPARAMS_SCOPES.split(" "),
      responseType: ["id_token", "token"],
      cookies: {
        secure: false,
        sameSite: "lax",
      },
    },
  },
  backend: {
    baseUrl: process.env.VUE_APP_BACKEND_BASEURL,
  },
};

// Vue.prototype.$currentAccount = undefined

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
  data: {
    currentAccount: undefined,
  },
});
