// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['502 202',`
<g transform="matrix(1.3333333,0,0,-1.3333333,0,793.7) " id="g288">
   <g id="g290" clip-path="url(#clipPath294)">
    <g id="g296">
     <path fill="#00aaff" fill-rule="evenodd" d="m348.76172,532.896c-42.764,28.547 -98.541,45.82 -159.566,45.82c-61.024,0 -116.803,-17.273 -159.568,-45.82c42.53,14.141 92.253,22.91 159.568,22.91c67.314,0 117.039,-8.769 159.566,-22.91" id="path298"/>
    </g>
    <g id="g300">
     <path fill="#00aaff" fill-rule="nonzero" d="m164.7261,518.05661c-3.135,0 -4.743,-2.543 -5.162,-5.654c-0.459,-3.432 -2.263,-15.345 -2.664,-18.371c-0.24,-1.812 -0.374,-3.706 -2.131,-4.485c-1.757,-0.777 -3.897,0.415 -5.72,1.967c-1.827,1.554 -28.841,24.554 -28.841,24.554c-1.549,1.355 -3.501,1.989 -5.361,1.989c-4.077,0 -7.996,-2.529 -7.996,-8.718l0,-38.354c0,-2.989 2.107,-4.898 4.625,-4.898c2.778,0 4.582,1.844 4.977,4.545c0,0 2.969,18.278 3.335,20.317c0.42,2.368 0.794,4.228 3.08,4.936c2.072,0.643 4.025,-0.559 6.944,-3.11c2.874,-2.51 27.2,-24.479 27.2,-24.479c1.862,-1.572 3.22,-2.207 5.854,-2.207c2.389,0 6.729,0.976 6.729,8.281l0,38.205c0,3.546 -2.193,5.482 -4.869,5.482" id="path302"/>
    </g>
    <g id="g304">
     <path fill="#00aaff" fill-rule="nonzero" d="m338.74121,477.18799c-21.197,-4.564 -45.453,0.313 -45.453,15.304c0,11.335 10.512,15.947 18.793,16.614c8.685,0.694 15.476,-1.132 18.974,-4.76c2.182,-2.265 1.575,-5.357 -2.712,-5.611c-3.95,-0.237 -11.354,-0.466 -17.518,-0.703c-3.498,-0.131 -5.369,-2.003 -5.369,-4.521c0,-1.995 1.504,-4.285 5.527,-4.304c5.772,-0.028 20.424,-0.116 27.697,-0.137c4.803,-0.012 7.17,2.676 7.17,8.202c0,8.75 -7.931,22.032 -31.142,22.032c-21.03,0 -36.244,-10.473 -36.244,-27.596c0,-24.283 35.734,-31.693 61.916,-22.669c2.482,0.851 4.73,2.635 4.011,5.406c-0.63,2.46 -2.64,3.396 -5.65,2.743" id="path306"/>
    </g>
    <g id="g308">
     <path fill="#00aaff" fill-rule="nonzero" d="m92.82571,477.18799c-21.196,-4.564 -45.451,0.313 -45.451,15.304c0,11.335 10.507,15.947 18.792,16.614c8.682,0.694 15.472,-1.132 18.971,-4.76c2.185,-2.265 1.575,-5.357 -2.709,-5.611c-3.951,-0.237 -11.356,-0.466 -17.52,-0.703c-3.5,-0.131 -5.372,-2.003 -5.372,-4.521c0,-1.995 1.505,-4.285 5.527,-4.304c5.774,-0.028 20.429,-0.116 27.702,-0.137c4.8,-0.012 7.169,2.676 7.169,8.202c0,8.75 -7.933,22.032 -31.143,22.032c-21.029,0 -36.245,-10.473 -36.245,-27.596c0,-24.283 35.736,-31.693 61.918,-22.669c2.481,0.851 4.729,2.635 4.009,5.406c-0.63,2.46 -2.636,3.396 -5.648,2.743" id="path310"/>
    </g>
    <g id="g312">
     <path fill="#00aaff" fill-rule="nonzero" d="m257.0029,500.5322c0.463,-13.237 1.713,-24.219 2.061,-28.066c0.453,-4.967 3.019,-6.38 5.597,-6.38c2.473,0 5.094,1.292 5.549,6.335c0.381,4.246 1.637,17.344 2.25,30.299c0.033,0.675 -0.439,0.964 -0.975,0.867l-13.798,-2.235c-0.405,-0.086 -0.698,-0.42 -0.684,-0.82" id="path314"/>
    </g>
    <g id="g316">
     <path fill="#00aaff" fill-rule="nonzero" d="m264.7002,519.3042c-6.332,0 -8.176,-4.96 -8.102,-8.001c0.012,-0.563 0.073,-2.128 0.161,-4.074c0.017,-0.443 0.416,-0.857 0.947,-0.792l14.398,2.338c0.459,0.093 0.678,0.428 0.7,0.792c0.035,0.676 0.054,1.373 0.064,1.698c0.084,2.935 -1.803,8.039 -8.168,8.039" id="path318"/>
    </g>
    <g id="g320">
     <path fill="#00aaff" fill-rule="nonzero" d="m243.53421,498.1929c-4.801,0 -19.613,-0.114 -29.094,-0.138c-4.601,-0.014 -5.748,-2.479 -5.748,-4.527c0,-1.779 1.233,-4.079 5.524,-4.302c6.537,-0.345 14.853,-0.731 18.691,-0.927c5.17,-0.265 5.238,-2.924 3.492,-5.199c-3.508,-4.566 -12.047,-7.558 -20.902,-7.558c-11.58,0 -24.318,6.587 -24.318,17.028c0,9.792 10.904,20.582 43.3,16.028c2.846,-0.4 5.651,0.112 6.35,2.747c0.744,2.8 -1.078,4.602 -4.758,5.572c-7.338,1.931 -14.029,2.387 -20.426,2.387c-21.804,0 -39.286,-10.412 -39.286,-27.228c0,-17.955 17.421,-26.739 39.134,-26.739c24.252,0 36.062,13.197 36.062,24.737c0,5.877 -2.99,8.119 -8.021,8.119" id="path322"/>
    </g>
   </g>
  </g>`]
