export default {
    chargeBack(state) {
        return state.chargeBackResponse;
    },
    shouldUpdate(state) {
        // const TS = new Date().getTime();
        const currentTS = state.currentTS;
        const lastLoadingTS = state.lastLoadingTS;
        if (!lastLoadingTS) {
            return true;
        }
        return (currentTS - lastLoadingTS) / 1000 > 3600;
    },
};