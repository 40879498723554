export default {
    setChargeBack(state, payload) {
        state.chargeBackResponse = payload;
    },
    setLastLoadingTS(state) {
        state.lastLoadingTS = new Date().getTime();
    },
    setCurrentTS(state) {
        state.currentTS = new Date().getTime();
    },
};