import axios from "axios";

export default {
  async loadWipParameters(context, payload) {
    context.commit("setCurrentTS");
    // if (!payload.refresh && !context.getters.shouldUpdate) {
    //   return;
    // }
    try {
      const config = {
        headers: {
          authorizationToken: "Bearer " + payload.accessToken,
        },
      };
      const URL = process.env.VUE_APP_BACKEND_BASEURL + "/portal/parameters";
      const response = await axios.get(URL, config);
      if (response.status == 200) {
        for (const item of response.data) {
          if (item.CategoryID === "Parameters" && item.SubCategoryID === "WorkInProgress") {
            context.commit("setWorkInProgress", item.Components);
            context.commit("setLastLoadingTS");
          }
        }
      }
    } catch (e) {
      console.log(e);
      context.commit("setWorkInProgress", {});
    }
  },
  async loadMessagesParameters(context, payload) {
    context.commit("setCurrentTS");
    // if (!payload.refresh && !context.getters.shouldUpdate) {
    //   return;
    // }
    try {
      const config = {
        headers: {
          authorizationToken: "Bearer " + payload.accessToken,
        },
      };
      const URL = process.env.VUE_APP_BACKEND_BASEURL + "/portal/parameters";
      const response = await axios.get(URL, config);
      if (response.status == 200) {
        for (const item of response.data) {
          if (item.CategoryID === "Parameters" && item.SubCategoryID === "Messages") {
            context.commit("setMessages", item.Messages);
            context.commit("setLastLoadingTS");
          }
        }
      }
    } catch (e) {
      console.log(e);
      context.commit("setWorkInProgress", {});
    }
  },
  async loadRegionsParameters(context, payload) {
    context.commit("setCurrentTS");
    // if (!payload.refresh && !context.getters.shouldUpdate) {
    //   return;
    // }
    try {
      const config = {
        headers: {
          authorizationToken: "Bearer " + payload.accessToken,
        },
      };
      const URL = process.env.VUE_APP_BACKEND_BASEURL + "/portal/parameters";
      const response = await axios.get(URL, config);
      for (const item of response.data) {
        if (response.status == 200) {
          if (item.CategoryID === "Parameters" && item.SubCategoryID === "Regions") {
            context.commit("setRegions", item.Regions);
            context.commit("setLastLoadingTS");
          }
        }
      }
    } catch (e) {
      console.log(e);
      context.commit("setRegions", []);
    }
  },
};
